<template>
  <!-- 新增帮助中心 -->
  <div>
    <!-- 填写表单 -->
    <el-form ref="ruleForm" v-model="ruleForm" label-width="120px" :rules="rules">
      <el-form-item label="标题" prop="categoryName">
        <el-input v-model="ruleForm.title" :disabled="true" placeholder="请输入标题" size="mini" style="width: 300px">{{
          ruleForm.adminName
        }}</el-input>
      </el-form-item>
      <el-form-item label="频道" prop="caption">
        <el-select v-model="value" :disabled="true" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推荐">
        <el-radio-group :disabled="true" v-model="ruleForm.recommend">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="推荐排序" prop="serialNumber">
        <el-input :disabled="true" v-model="ruleForm.serialNumber" placeholder="请输入序号" size="mini" style="width: 220px"></el-input>
      </el-form-item>
      <el-form-item label="文章内容">
        <quill-editor
          :disabled="true"
          class="info-editor"
          v-model="content"
          ref="QuillEditor"
          :options="editorOption"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item label="">
        <div style="margin-top: 60px">
          <el-button type="" @click="getBack">返回</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// require styles
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
const titleConfig = {
  'ql-bold': '加粗',
  'ql-color': '字体颜色',
  'ql-font': '字体',
  'ql-code': '插入代码',
  'ql-italic': '斜体',
  'ql-link': '添加链接',
  'ql-background': '背景颜色',
  'ql-size': '字体大小',
  'ql-strike': '删除线',
  'ql-script': '上标/下标',
  'ql-underline': '下划线',
  'ql-blockquote': '引用',
  'ql-header': '标题',
  'ql-indent': '缩进',
  'ql-list': '列表',
  'ql-align': '文本对齐',
  'ql-direction': '文本方向',
  'ql-code-block': '代码块',
  'ql-formula': '公式',
  'ql-image': '图片',
  'ql-video': '视频',
  'ql-clean': '清除字体样式'
}

export function addQuillTitle() {
  const oToolBar = document.querySelector('.ql-toolbar'),
    aButton = oToolBar.querySelectorAll('button'),
    aSelect = oToolBar.querySelectorAll('select'),
    aSpan = oToolBar.querySelectorAll('span')
  aButton.forEach(item => {
    if (item.className === 'ql-script') {
      item.value === 'sub' ? (item.title = '下标') : (item.title = '上标')
    } else if (item.className === 'ql-indent') {
      item.value === '+1' ? (item.title = '向右缩进') : (item.title = '向左缩进')
    } else if (item.className === 'ql-list') {
      item.value === 'ordered' ? (item.title = '有序列表') : (item.title = '无序列表')
    } else if (item.className === 'ql-header') {
      item.value === '1' ? (item.title = '标题H1') : (item.title = '标题H2')
    } else {
      item.title = titleConfig[item.classList[0]]
    }
  })
  aSelect.forEach(item => {
    if (item.className != 'ql-color' && item.className != 'ql-background') {
      item.parentNode.title = titleConfig[item.classList[0]]
    }
  })
  aSpan.forEach(item => {
    if (item.classList[0] === 'ql-color') {
      item.title = titleConfig[item.classList[0]]
    } else if (item.classList[0] === 'ql-background') {
      item.title = titleConfig[item.classList[0]]
    }
  })
}
// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  ['clean'] // remove formatting button
]
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      ruleForm: [],
      // 频道
      options: [
        {
          value: '选项1',
          label: '新手指南'
        },
        {
          value: '选项2',
          label: 'APP常见问题'
        },
        {
          value: '选项3',
          label: '官网常见问题'
        }
      ],
      value: '',
      //   验证规则
      rules: {
        categoryName: [{ required: true, message: '请输入频道名称', trigger: 'blur' }],
        serialNumber: [{ required: true, message: '请输入序号', trigger: 'blur' }]
      },
      editorOption: {
        placeholder: '请输入编辑内容',
        theme: 'snow', //主题风格
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector('#quill-upload input').click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          }
        }
      }, // 富文本编辑器配置
      content: '' //富文本内容
    }
  },
  mounted() {
    addQuillTitle()
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  created() {
    this.goHelp(this.$route.query.id)
  },
  methods: {
    goHelp(id) {
      this.axios
        .get(`/admin/help/findById?id=${id}`, {})
        .then(res => {
          console.log(res)
          this.ruleForm = res.data.data
        })
        .catch(err => {})
    },
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    // 校验
    checkout(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.open2()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 校验成功消息
    open2() {
      this.$message({
        message: '校验成功',
        type: 'success'
      })
    },
    // 保存成功消息
    open() {
      this.$message({
        message: '保存成功',
        type: 'success'
      })
    },
    // 保存
    submitForm(formName) {
      console.log(this.ruleForm)
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.getBack()
          this.open()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //   返回上一页面
    getBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.info-editor {
  height: 20vw;
  width: 60vw;
}
</style>
